import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { Link, navigate } from 'gatsby';
import Layout from '../components/layout/Layout';
import CardHeading from '../components/layout/CardHeading';
import CardFooter from '../components/layout/CardFooter';
import Error from '../components/Error';

const heading = {
  title: 'Confirm Account',
  desc: 'Please enter your email address below. We will send you the code if there is a match.',
};

export const ConfirmAccountElements = ({
  defaultError = '',
  defaultState = 0,
  defaultInputData = {
    email: '',
    code: '',
  },
}) => {
  const [error, setError] = useState(defaultError);
  const [stage, setStage] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);
  const [inputData, setInputData] = useState(defaultInputData);
  const { email, code } = inputData;

  const handleInputChange = (evt) => {
    evt.persist();

    const { name, value } = evt.target;
    setInputData((d) => ({ ...d, [name]: value }));
    setError('');
  };

  const handleResend = async (e) => {
    try {
      e.preventDefault();

      setIsLoading(true);
      await Auth.resendSignUp(email);
      setStage(1);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirm = async (e) => {
    try {
      e.preventDefault();

      setIsLoading(true);
      await Auth.confirmSignUp(email, code);
      navigate('/app/signin');
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    stage,
    emailField: {
      type: 'email',
      placeholder: 'Email',
      name: 'email',
      value: email,
      required: true,
      onChange: handleInputChange,
    },
    codeField: {
      type: 'text',
      placeholder: 'Code',
      name: 'code',
      value: code,
      onChange: handleInputChange,
    },
    resendButton: {
      type: 'submit',
      color: 'primary',
      block: true,
      disabled: isLoading,
      onClick: handleResend,
    },
    confirmButton: {
      type: 'submit',
      color: 'primary',
      block: true,
      disabled: isLoading,
      onClick: handleConfirm,
    },
  };
};
export const ConfirmAccount = (props) => {
  const { error, stage, emailField, codeField, resendButton, confirmButton } = ConfirmAccountElements(props);

  return (
    <Layout data-test="confirm-account-component" hideIcons="true">
      <CardHeading heading={heading} />

      {stage === 0 && (
        <Form>
          {error && <Error data-test="confirm-account-component-error" errorMessage={error} />}
          <FormGroup>
            <Input {...emailField} />
          </FormGroup>
          <Button {...resendButton}>Resend Code</Button>
        </Form>
      )}

      {stage === 1 && (
        <Form>
          {error && <Error data-test="confirm-account-component-error" errorMessage={error} />}
          <FormGroup>
            <Input {...codeField} />
          </FormGroup>
          <Button {...confirmButton}>Confirm Account</Button>
        </Form>
      )}

      <CardFooter>
        <p className="t-text">
          <Link to="/app/signin" className="link-heading">
            Sign In
          </Link>
        </p>
      </CardFooter>
    </Layout>
  );
};

export default ConfirmAccount;
