import React from 'react';

const Error = (props) => {
  return (
    <div data-test="error-component">
      <div className="error-message">
        <p data-test="error-message-text">{props.errorMessage ? props.errorMessage.message : ''}</p>
      </div>
    </div>
  );
};

export default Error;
